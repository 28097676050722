import React from 'react';
import {Button, DatePicker, Divider, message, Modal, Space, Spin, Table, TableProps} from 'antd';
import API from '../../common/api';
import {ColumnsType} from 'antd/es/table';
import 'dayjs/locale/zh-cn';
import App from '../../../App';
import BaseListPage from "../../common/listpage/BaseListPage";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {BasePageType} from "../../common/listpage/BaseListPageType";
import {BasePageDate} from "../../common/listpage/ListCommon";
import Fetch from "../../common/FetchIt";
import {DownloadStatus, RongTitleObj, troupeStatusArr} from "../util/TroupeArtistUtil";
import {Dayjs} from "dayjs";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";
import common from "../../common/common";
import {MenuRole} from "../../common/MenuRole";

const {RangePicker} = DatePicker;

type TableRowSelection<T extends object = object> = TableProps<T>['rowSelection'];

type User = { name: string | '', msg: string | '', id: number | 0, troupeStatus: number | 0 };
type HospitalPatientType = BasePageType & {
    searchData: {
        name: undefined,
        phone: undefined,
        groupName: undefined,
        rongTitle: undefined,
        createDate: [Dayjs | null, Dayjs | null],
        page: 0
    }
    btnQ: MenuRole,
    statusListArr: any[] | undefined,
    selectedRowKeys: React.Key[],
    fixedTop: boolean
}
const defData: HospitalPatientType = {
    ...BasePageDate,
    searchData: {
        name: undefined,
        phone: undefined,
        groupName: undefined,
        rongTitle: undefined,
        createDate: [null, null],
        page: 0
    },
    btnQ: {},
    statusListArr: [],
    selectedRowKeys: [],
    fixedTop: true
}

const ParamsObj = {
    name: '姓名',
    phone: '手机号',
    groupName: '组名称',
    groupId: '组名称',
    startTime: '开始时间',
    endTime: '结束时间',
    rongTitle: '荣称',
    troupeStatus: '团员状态'
}

const confirm = Modal.confirm;

class TroupDownloadListClass extends BaseListPage {

    private troupTransferGroupModelResult: any = null;

    state: HospitalPatientType = {
        ...defData,
        btnQ: {}
    };

    componentDidMount() {
        // BoneSelectStatus([105, 106, 107], this)
        super.componentDidMount();
    }

    loadData = () => {
        const {pageSize, searchData} = this.state;
        const startTime = searchData.createDate[0]?.format('YYYY-MM-DD')
        const endTime = searchData.createDate[1]?.format('YYYY-MM-DD')
        let url = `${API.troup.download.query_page}?page=${searchData.page}&size=${pageSize}`;
        // url = url + addParams("name", searchData.name, STRING_STR);
        // url = url + addParams("phone", searchData.phone, STRING_STR);
        // url = url + addParams("groupName", searchData.groupName, STRING_STR);
        // url = url + addParams("startTime", startTime, TIME_STR);
        // url = url + addParams("endTime", endTime, TIME_STR);
        // url = url + addParams("rongTitle", searchData.rongTitle, STRING_STR);
        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                importLoading: false
            });
        })
    };


    reset = () => {
        super.reset(defData.searchData);
    };


    download = (id: number, name: string) => {

        const that = this;
        confirm({
            title: `是否确认下载`,
            onOk() {
                Fetch.get(`${API.troup.download.download}?id=${id}`,).then((data) => {
                    if (data) {
                        const currentTimeStamp = Math.floor(Date.now() / 1000);
                        common.downloadFile(name + ".xlsx", API.userCenterDomain + data, (callback: any) => {
                            if (callback === true) {
                                message.success('处理完成');
                            } else {
                                message.error('下载失败，请联系管理员');
                            }
                            that.setState({
                                importLoading: false,
                            });
                        });
                    } else {
                        message.error('下载失败，请联系管理员');
                        that.setState({
                            importLoading: false,
                        });
                    }
                });
            }
        })
    }

    isEmptyValue = (value: any) => {

        if (value === null || value === undefined || value === '' || value === 0) {
            return true;
        }
        if (Array.isArray(value)) {
            if (value.length > 0) {
                value.forEach((item) => {
                    if (!(item === null || item === undefined)) {
                        return false;
                    }
                })
                return true;
            }
        }
        return false;
    };

    isAnyValueNotEmpty = (obj: any) => {

        return Object.values(obj).some((value) => {
            return !this.isEmptyValue(value)
        })
    };

    batchApproved = () => {

        const {selectedRowKeys} = this.state;
        if (!selectedRowKeys) {
            message.success('请选择需要通过的团员');
        }
        const that = this;
        confirm({
            title: `是否审核通过`,
            onOk() {
                that.doLoading();
                let params: any[] = [];
                selectedRowKeys.forEach((item) => {
                    params.push({id: item});
                })
                Fetch.putJSON(API.troup.guru.batch_approved, params).then(() => {
                    message.success('提交成功');
                    that.hideLoading();
                    that.loadData();
                }).catch(e => {
                    that.hideLoading();
                });
            }
        })
    }

    deletedReserve = (id: number) => {

        const that = this;
        confirm({
            title: `是否确认删除？`,
            onOk() {
                that.doLoading()
                Fetch.postJSON(API.troup.guru.deleted_by_id, {id: id}).then(() => {
                    message.success('提交成功');
                    that.setState({
                        importLoading: false,
                        selectedRowKeys: []
                    })
                    that.loadData();
                }).catch(e => {
                    that.setState({
                        importLoading: false
                    })
                });
            }
        })
    }

    render() {
        const {
            pageSize, totalElements,
            btnQ, searchData, content,
        } = this.state;

        const params = (value: any) => {

            if (value) {
                const parse = JSON.parse(value);
                let result = "";
                let indexF = 0

                Object.entries(parse).some(([key, value], index, array) => {
                    if (!this.isEmptyValue(value)) {
                        if (indexF !== 0) {
                            result = result + "---"
                        }
                        if (key === "rongTitle") {
                            // @ts-ignore
                            result = result + ParamsObj[key] + ":" + RongTitleObj[value];
                        } else if (key === "troupeStatus") {
                            // @ts-ignore
                            result = result + ParamsObj[key] + ":" + troupeStatusArr[value];
                        } else {
                            result = result + ParamsObj[key] + ":" + value;
                        }
                        indexF++;
                    }
                });
                return result;
            }
        }

        const columns: ColumnsType<User> = [

            {
                title: '下载参数',
                dataIndex: 'queryParams',
                align: `center`,
                render: (value: any, record: any) => {
                    return params(value);
                },
            },
            {
                title: '下载状态',
                dataIndex: 'downloadStatus',
                align: `center`,
                render: (value: any, record: any) => {
                    return (
                        <span style={{
                            padding: '5px 10px',
                            borderRadius: '15px',
                            border: '1px solid ',
                            color: DownloadStatus[value].color
                        }}>
                            {DownloadStatus[value].title}
                        </span>
                    );
                },
            },
            {
                title: '生成文件用时',
                dataIndex: 'downloadTime',
                align: `center`,
                render: (value: any, record: any) => {
                    return (<>
                            {value}秒
                        </>
                    );
                },
            },
            {
                title: '文件名',
                dataIndex: 'fileName',
                align: `center`,
            },
            {
                title: '创建时间',
                dataIndex: 'createDate',
                align: `center`,
            },
            {
                title: '描述',
                dataIndex: 'msg',
                align: `center`,
            },
            {
                title: '操作',
                align: `center`,
                dataIndex: 'status',
                render: (value: any, record: any) => {
                    return (
                        <Space split={<Divider type="vertical"/>}>
                            {(btnQ.R270 && record.downloadStatus === 3) && (
                                <>
                                    <Button danger type={"link"}
                                            onClick={() => this.download(record.id, record.fileName)}>
                                        下载
                                    </Button>
                                </>
                            )}
                        </Space>
                    );
                },
            },
        ];
        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        {/*<Space onKeyDown={this.handleKeyDown} wrap>*/}
                        {/*    <Input*/}
                        {/*        placeholder="姓名"*/}
                        {/*        value={searchData.name}*/}
                        {/*        onChange={(e) => {*/}
                        {/*            this.changeCondition({page: 0, name: e.target.value})*/}
                        {/*        }*/}
                        {/*        }*/}
                        {/*    />*/}
                        {/*    <Input*/}
                        {/*        placeholder="手机号"*/}
                        {/*        value={searchData.phone}*/}
                        {/*        onChange={(e) => {*/}
                        {/*            this.changeCondition({page: 0, phone: e.target.value})*/}
                        {/*        }*/}
                        {/*        }*/}
                        {/*    />*/}
                        {/*    <Input*/}
                        {/*        placeholder="所在团队"*/}
                        {/*        value={searchData.groupName}*/}
                        {/*        onChange={(e) => {*/}
                        {/*            this.changeCondition({page: 0, groupName: e.target.value})*/}
                        {/*        }*/}
                        {/*        }*/}
                        {/*    />*/}
                        {/*    <RangePicker*/}
                        {/*        locale={locale}*/}
                        {/*        value={searchData.createDate}*/}
                        {/*        onChange={e => this.changeCondition({createDate: e, page: 0})}*/}
                        {/*    />*/}
                        {/*    <Select*/}
                        {/*        style={{width: 220}}*/}
                        {/*        placeholder="艺术团荣称"*/}
                        {/*        optionFilterProp="children"*/}
                        {/*        options={ReserveRongTitleArr}*/}
                        {/*        value={searchData.rongTitle}*/}
                        {/*        allowClear={true}*/}
                        {/*        onChange={(e) => {*/}
                        {/*            this.changeCondition({page: 0, rongTitle: e})*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*    <Button onClick={this.loadData}>*/}
                        {/*        搜索*/}
                        {/*    </Button>*/}
                        {/*    <Button onClick={() => this.reset()}>重置</Button>*/}
                        {/*    <Button onClick={() => this.download()}>下载</Button>*/}
                        {/*</Space>*/}
                        <Table
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1
                                    }
                                )
                            }
                        />
                    </div>
                </Spin>
                {/*<TroupTransferGroupModel*/}
                {/*    width={500}*/}
                {/*    title={'调整团队'}*/}
                {/*    id={this.state.selectId}*/}
                {/*    onCancel={this.closeModal}*/}
                {/*    ref={(c) => (this.troupTransferGroupModelResult = c)}*/}
                {/*/>*/}
            </>
        );
    }

}

export default function TroupDownloadList() {

    const pageKey = getKey();
    return (

        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <TroupDownloadListClass/>
        </App>
    );
}
