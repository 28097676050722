import React, {Component} from 'react';
import {Button, Card, Descriptions, Image, Space, Spin,} from 'antd';
import App from '../../../App';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import common from '../../common/common';
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {decryptData} from "../../common/crypto";

import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";
import {genderArr} from "../../common/DictCommon";
import {readFile} from "./types";

type TroupeArtistDetailType = {
    id: string | '',
    importLoading: boolean,
    data: {
        title: string | '',
        subTitle: string | '',
        cover: string | '',
        coverUrl: string | '',
        status: number | 0,
        contentUrl: string | '',
        contentStr: string | ''
    }
}

const TroupeArtistDetail = {
    id: '',
    importLoading: true,
    data: {
        title: '',
        subTitle: '',
        cover: '',
        status: 0,
        contentUrl: '',
        contentStr: '',
        coverUrl: ''
    }
}

class ActivityInfoDetailClass extends Component<{}, TroupeArtistDetailType> {

    state: TroupeArtistDetailType = {
        ...TroupeArtistDetail
    };

    componentDidMount() {
        this.setState({
            id: decryptData(common.getQuery('id')),
        }, () => {
            this.getData()
        })
    };

    getData() {

        const {id} = this.state;
        let url = `${API.activity_info.get_detail}?id=${id}`;
        Fetch.get(url).then((data) => {
            readFile(data.contentUrl).then((res) => {
                data.contentStr = res;
                this.setState({
                    data: data,
                    importLoading: false
                })
            })
        });
    }

    cancel = () => {
        window.history.go(-1);
    }
    back = () => {
        // @ts-ignore
        window.history.back(-1)
    }


    render() {
        const {data} = this.state;
        return (
            <div>
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Spin tip={'加载中'} spinning={this.state.importLoading}>
                        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                            {data ? (
                                <>
                                    <Card size="small"
                                          title={`基本信息`}>
                                        <Space direction="vertical">
                                            <>
                                                <Descriptions>
                                                    <Descriptions.Item label="封面图">
                                                        <Image src={API.domain + data.coverUrl} width={100}/>
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Descriptions>
                                                    <Descriptions.Item label="标题">
                                                        {data.title}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Descriptions>
                                                    <Descriptions.Item label="副标题">
                                                        {data.subTitle}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Descriptions>
                                                    <Descriptions.Item label="内容">
                                                        <div dangerouslySetInnerHTML={{__html: data.contentStr}}/>
                                                    </Descriptions.Item>
                                                </Descriptions>

                                            </>
                                        </Space>
                                    </Card>
                                </>
                            ) : ('')}
                            <Card>
                                <Space>
                                    <Button
                                        // type="primary"
                                        // size="large"
                                        onClick={this.cancel}
                                    >
                                        返回
                                    </Button>
                                </Space>
                            </Card>
                        </Space>
                    </Spin>
                </div>
            </div>
        );
    }
}

export default function ActivityInfoDetail() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]} openKeys={pageKey.openKeys}>
            <ActivityInfoDetailClass/>
        </App>
    );
}
