import React, {Component} from 'react';
import {Button, Card, Col, Form, FormInstance, Input, message, Modal, Row, Select, Space} from 'antd';
import App from '../../../App';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import FormItem from 'antd/lib/form/FormItem';
import {decryptData} from "../../common/crypto";

import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";
import {getInitEdit, TroupeArtistEditType} from "../util/TroupeArtistType";
import {buildTroupFileData} from "../util/TroupeArtistUtil";
import {genderArr} from "../../common/DictCommon";
import DhZoneSelect from './DhZoneSelect';
import common from "../../common/common";
import {v4 as uuidV4} from 'uuid';

const {confirm} = Modal;

class TroupeReserveEditClass extends Component<{}, TroupeArtistEditType> {

    formRef = React.createRef<FormInstance>();
    initPaymentMethodId = '0';
    state: TroupeArtistEditType = {
        ...getInitEdit(),
        token: uuidV4(),
    };

    componentDidMount() {
        this.setState({
            id: decryptData(common.getQuery('id')),
        }, () => {
            this.getData()
        })
    };

    getData = () => {

        const {id} = this.state;
        let url = `${API.troup.guru.get_admin_edit_detail}?id=${id}`;
        Fetch.get(url).then((data) => {
            buildTroupFileData(data)
            this.getDictData(data);
        });
    }

    getDictData = (pageData: any) => {

        Fetch.get(`${API.dict.find_by_types}?types=nationality,politics`).then((data) => {
            console.log(pageData)
            const nationality = data['nationality'];
            const politics = data['politics'];
            let dictInfo: { politics: any[], nationality: any[] } = {politics: [], nationality: []}
            for (let i = 0; i < nationality.length; i++) {
                const item = nationality[i];
                dictInfo.nationality.push({value: item.code, label: item.name})
            }
            for (let i = 0; i < politics.length; i++) {
                const item = politics[i];
                dictInfo.politics.push({value: item.code, label: item.name})
            }
            pageData.dictInfo = dictInfo;
            pageData.ethnic = '02'
            pageData.politics = '02'
            this.setState({
                importLoading: false,
                data: pageData
            }, () => {
                this.formRef.current?.setFieldsValue({
                    ...pageData
                })
            })
        });
    }

    cancel = () => {
        window.history.go(-1);
    }
    submit = (values: any) => {

        const that = this;
        const {addressStr, originStr} = this.state;
        confirm({
            title: `是否保存患者账户信息？`,
            okText: `提交`,
            onOk() {
                values.id = that.state.id;
                values.updateToken = that.state.token
                Fetch.postJSON(API.troup.guru.admin_update, values).then(() => {
                    message.success('提交成功');
                    window.history.go(-1);
                });
            }
        });
    }

    doAdminExitAccount = (values: any) => {

        Fetch.postJSON(API.cooperate_user_account.do_admin_exit_account, values).then(() => {
            message.success('提交成功');
            window.history.go(-1);
        });
    };

    setAreaStrFun = (val: string) => {

        // this.setState({
        //     addressStr: val
        // },()=>{
        //
        // });
        const data = {addrStr: val};
        this.formRef.current?.setFieldsValue({...data})
    };

    setOriginStrFun = (val: string) => {

        const data = {originStr: val};
        this.formRef.current?.setFieldsValue({...data})
    };


    render() {
        const {data, id} = this.state;
        // @ts-ignore
        return (
            <div>
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Form
                        onFinish={this.submit}
                        ref={this.formRef}
                    >
                        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                            <Card size="small"
                                  title={`基本信息`}>
                                <Row gutter={40}>
                                    <Col span={8}>
                                        <FormItem
                                            name="name"
                                            label="姓名"
                                            labelCol={{span: 3}}
                                            wrapperCol={{span: 10}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            <Input placeholder="收款人姓名"/>
                                        </FormItem>
                                    </Col>
                                    <Col span={8}>
                                        <FormItem
                                            label="性别"
                                            labelCol={{span: 3}}
                                            wrapperCol={{span: 10}}
                                        >
                                            <Input disabled placeholder="性别" value={genderArr[data.gender]}/>
                                        </FormItem>
                                    </Col>
                                    <Col span={8}>
                                        <FormItem
                                            label="年龄"
                                            labelCol={{span: 3}}
                                            wrapperCol={{span: 10}}
                                        >
                                            <Input disabled placeholder="年龄" value={data.age || ''}/>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={40}>
                                    <Col span={8}>
                                        <FormItem
                                            label="出生日期"
                                            labelCol={{span: 3}}
                                            wrapperCol={{span: 10}}
                                        >
                                            <Input disabled placeholder="出生日期" value={data.birthday || ''}/>
                                        </FormItem>
                                    </Col>
                                    <Col span={8}>
                                        <FormItem
                                            name="idNumber"
                                            label="身份证号"
                                            labelCol={{span: 3}}
                                            wrapperCol={{span: 10}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            <Input placeholder="身份证号"/>
                                        </FormItem>
                                    </Col>
                                    <Col span={8}>
                                        <FormItem
                                            name="phone"
                                            label="联系电话"
                                            labelCol={{span: 3}}
                                            wrapperCol={{span: 10}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            <Input placeholder="联系电话"/>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={40}>
                                    <Col span={8}>
                                        <FormItem
                                            label="所在团队"
                                            labelCol={{span: 3}}
                                            wrapperCol={{span: 10}}
                                        >
                                            <Input disabled placeholder="出生日期" value={data.groupName || ''}/>
                                        </FormItem>
                                    </Col>
                                    <Col span={8}>
                                        <FormItem
                                            name="addrStr"
                                            label="居住地址"
                                            labelCol={{span: 3}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: true, message: '必填'}]}
                                            style={{marginBottom: '0px'}}
                                        >
                                            <DhZoneSelect
                                                setAreaStr={this.setAreaStrFun}
                                                form={this.formRef}
                                                loadData={{
                                                    provinceId: data.provinceId,
                                                    cityId: data.cityId
                                                }}
                                                fildName={null}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col span={8}>
                                        <FormItem
                                            name="address"
                                            label="详细地址"
                                            labelCol={{span: 3}}
                                            wrapperCol={{span: 10}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            <Input placeholder="详细地址"/>
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Card>
                            <Card size="small"
                                  title={`其他信息`}>
                                <Row gutter={40}>
                                    <Col span={8}>
                                        <FormItem
                                            name="originStr"
                                            label="籍贯"
                                            labelCol={{span: 3}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: false, message: '必填'}]}
                                            style={{marginBottom: '0px'}}
                                        >
                                            <DhZoneSelect
                                                setAreaStr={this.setOriginStrFun}
                                                form={this.formRef}
                                                loadData={{
                                                    provinceId: data.originProvinceId,
                                                    cityId: data.originCityId
                                                }}
                                                fildName={{
                                                    provinceId: 'originProvinceId',
                                                    cityId: 'originCityId',
                                                    districtId: 'originDistrictId'
                                                }}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col span={8}>
                                        <FormItem
                                            name="ethnic"
                                            label="民族"
                                            labelCol={{span: 3}}
                                            wrapperCol={{span: 10}}
                                            rules={[{required: false, message: '必填'}]}
                                        >
                                            <Select
                                                style={{width: 120}}
                                                options={data.dictInfo.nationality}
                                            >
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    <Col span={8}>
                                        <FormItem
                                            name="politics"
                                            label="政治面貌"
                                            labelCol={{span: 3}}
                                            wrapperCol={{span: 10}}
                                            rules={[{required: false, message: '必填'}]}
                                        >
                                            <Select
                                                style={{width: 120}}
                                                options={data.dictInfo.politics}
                                            >
                                            </Select>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={40}>
                                    <Col span={8}>
                                        <FormItem
                                            name="specialtyStr"
                                            label="兴趣爱好"
                                            labelCol={{span: 3}}
                                            wrapperCol={{span: 10}}
                                            rules={[{required: false, message: '必填'}]}
                                        >
                                            <Input placeholder="兴趣爱好"/>
                                        </FormItem>
                                    </Col>
                                    <Col span={8}>
                                        <FormItem
                                            name="groupDuties"
                                            label="团内职务"
                                            labelCol={{span: 3}}
                                            wrapperCol={{span: 10}}
                                            rules={[{required: false, message: '必填'}]}
                                        >
                                            <Input placeholder="团内职务"/>
                                        </FormItem>
                                    </Col>
                                    <Col span={8}>
                                        <FormItem
                                            name="originalUnit"
                                            label="原单位"
                                            labelCol={{span: 3}}
                                            wrapperCol={{span: 10}}
                                            rules={[{required: false, message: '必填'}]}
                                        >
                                            <Input placeholder="原单位"/>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={40}>
                                    <Col span={8}>
                                        <FormItem
                                            name="originalDuties"
                                            label="原职务"
                                            labelCol={{span: 3}}
                                            wrapperCol={{span: 10}}
                                            rules={[{required: false, message: '必填'}]}
                                        >
                                            <Input placeholder="原职务"/>
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Card>
                            <Card>
                                <Space>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        保存
                                    </Button>
                                    <Button
                                        style={{marginRight: '15px'}}
                                        onClick={this.cancel}
                                    >
                                        取消
                                    </Button>
                                </Space>
                            </Card>
                        </Space>
                    </Form>
                </div>
            </div>
        );
    }
}

export default function TroupeReserveEdit1() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <TroupeReserveEditClass/>
        </App>
    );
}
