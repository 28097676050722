import PATH from "../pagePath";

export type BreadcrumbType = {
    url: string | ''
    name: string | ''
    root: boolean | true
}
const BREADCRUMB_INFO_CONFIG: Map<string, BreadcrumbType> = new Map();

BREADCRUMB_INFO_CONFIG.set(PATH.troup.guru.list, {
    url: PATH.troup.guru.list,
    name: '艺术家列表',
    root: true
});
BREADCRUMB_INFO_CONFIG.set(PATH.troup.guru.detail, {
    url: PATH.troup.guru.detail,
    name: '艺术家详情',
    root: false
});

BREADCRUMB_INFO_CONFIG.set(PATH.troup.reserve.list, {
    url: PATH.troup.reserve.list,
    name: '预备团员列表',
    root: true
});
BREADCRUMB_INFO_CONFIG.set(PATH.troup.reserve.detail, {
    url: PATH.troup.reserve.detail,
    name: '预备团员详情',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.troup.group.list, {
    url: PATH.troup.group.list,
    name: '基层艺术团',
    root: true
});
BREADCRUMB_INFO_CONFIG.set(PATH.troup.sign.list, {
    url: PATH.troup.sign.list,
    name: '艺术家签名验证码',
    root: true
});
BREADCRUMB_INFO_CONFIG.set(PATH.troup.group.detail, {
    url: PATH.troup.group.detail,
    name: '基层艺术团详情',
    root: false
});

BREADCRUMB_INFO_CONFIG.set(PATH.activityInfo.list, {
    url: PATH.activityInfo.list,
    name: '活动咨询',
    root: true
});

BREADCRUMB_INFO_CONFIG.set(PATH.activityInfo.detail, {
    url: PATH.activityInfo.detail,
    name: '咨询详情',
    root: false
});

BREADCRUMB_INFO_CONFIG.set(PATH.activityInfo.edit, {
    url: PATH.activityInfo.edit,
    name: '编辑咨询',
    root: false
});

BREADCRUMB_INFO_CONFIG.set(PATH.groupInfo.list, {
    url: PATH.groupInfo.list,
    name: '团员咨询',
    root: true
});

BREADCRUMB_INFO_CONFIG.set(PATH.groupInfo.detail, {
    url: PATH.groupInfo.detail,
    name: '咨询详情',
    root: false
});

BREADCRUMB_INFO_CONFIG.set(PATH.groupInfo.edit, {
    url: PATH.groupInfo.edit,
    name: '编辑咨询',
    root: false
});

BREADCRUMB_INFO_CONFIG.set(PATH.orgInfo.list, {
    url: PATH.orgInfo.list,
    name: '机构咨询',
    root: true
});

BREADCRUMB_INFO_CONFIG.set(PATH.orgInfo.detail, {
    url: PATH.orgInfo.detail,
    name: '咨询详情',
    root: false
});

BREADCRUMB_INFO_CONFIG.set(PATH.orgInfo.edit, {
    url: PATH.orgInfo.edit,
    name: '编辑咨询',
    root: false
});


BREADCRUMB_INFO_CONFIG.set(PATH.artInfo.list, {
    url: PATH.artInfo.list,
    name: '艺术咨询',
    root: true
});

BREADCRUMB_INFO_CONFIG.set(PATH.artInfo.detail, {
    url: PATH.artInfo.detail,
    name: '咨询详情',
    root: false
});

BREADCRUMB_INFO_CONFIG.set(PATH.artInfo.edit, {
    url: PATH.artInfo.edit,
    name: '编辑咨询',
    root: false
});

BREADCRUMB_INFO_CONFIG.set(PATH.troup.download.list, {
    url: PATH.troup.download.list,
    name: '下载历史',
    root: false
});

export default BREADCRUMB_INFO_CONFIG;
