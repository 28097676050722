import React, {useEffect, useState} from 'react';
import 'dayjs/locale/zh-cn';
import {Breadcrumb, Card, Col, Row, Tabs} from 'antd';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import {useParams} from 'react-router-dom';
import App from '../../App';

const {TabPane} = Tabs;

export function parseRole(roleId: number) {
    switch (roleId) {
        case 5:
            return '爱心网友';
        case 1:
            return '志愿者';
        case 3:
            return '乐龄志愿服务组织';
        case 4:
            return '省级老基会';
        case 2:
            return '国家老基会';
    }
}

export function parseGender(gender: number) {
    switch (gender) {
        case 0:
            return '未知';
        case 1:
            return '男';
        case 2:
            return '女';
    }
}

function UserDetail() {
    const [user, setUser] = useState<any>({});

    const {id} = useParams<{ id: string }>();

    useEffect(
        function () {
            if (!id) {
                return;
            }
            Fetch.get(`${API.user.show}?userId=${id}`).then((data) => {
                setUser(data);
            });
        },
        [id]
    );

    return (
        <>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>
                    <a href="/#/user">用户管理</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>用户详情</Breadcrumb.Item>
            </Breadcrumb>
            <div
                className="site-layout-background"
                style={{padding: 24, minHeight: 720}}
            >
                <h3>昵称：{user.wxNickname}</h3>
                <Card style={{marginBottom: '15px'}}>
                    <Row>
                        <Col span={4}>性别: {parseGender(user.gender)}</Col>
                        <Col span={8}>最近登录时间: {user.loginDate}</Col>
                        <Col span={6}>用户角色: {parseRole(user.roleId)}</Col>
                    </Row>
                </Card>
            </div>
        </>
    );
}

export default function UserDetailIndex() {
    return (
        <App selectedKeys={['user']}>
            <UserDetail/>
        </App>
    );
}
