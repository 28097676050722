import API from "../../common/api";
import {TroupeArtistDetailType} from "./TroupeArtistType";

export function buildTroupFileData(data: TroupeArtistDetailType["data"]) {

    if (data.files && data.files.length > 0) {

        data.files.forEach((item: any) => {
            const obj = {
                id: item.fileId,
                uid: item.fileId,
                fileId: item.fileId,
                name: item.name,
                url: API.userCenterDomain + item.url,
                status: 'done',
            };
            if (item.fileType === 1) {
                data.signUrl = obj
            } else if (item.fileType === 2) {
                data.certificate = obj
            }
        });
    }
}

export const RongTitleArr = [
    {
        value: '01',
        label: '荣誉团员',
    },
    {
        value: '02',
        label: '青年之友',
    },
    {
        value: '03',
        label: '艺术指导',
    },
    {
        value: '04',
        label: '顾问委员',
    },
]

export const ReserveRongTitleArr = [
    {
        value: '06',
        label: '群众团员',
    },
    {
        value: '07',
        label: '艺术团团员',
    },
    {
        value: '08',
        label: '文艺志愿者',
    }
]

export const RongTitleObj = {
    '06': '群众团员',
    '07': '艺术团团员',
    '08': '文艺志愿者',
}

export const SignStatusArr = [
    {
        value: 1,
        label: '未签',
    },
    {
        value: 2,
        label: '已签',
    }
]

export const TroupeStatusSelect = [
    {
        value: '1',
        label: '待审核',
    },
    // {
    //     value: '07',
    //     label: '审核退回',
    // },
    {
        value: '3',
        label: '审核通过',
    }
]

export const troupeStatusArr = {

    1: '待审核',
    2: '审核退回',
    3: '审核通过',
}

export const DownloadStatus = {

    1: {title: '初始化', color: '#90caf9'},
    2: {title: '生成中', color: '#42a5f5'},
    3: {title: '创建完成', color: '#81c784'},
    4: {title: '创建失败', color: '#e57373'},
}