const PATH = {
    login: '/',
    index: '/index',
    wxLogin: '/wxLogin',
    registered: {
        doRegistered: '/register',
    },
    account: {
        list: '/account',
    },
    alt: {
        category: '/category',
        atlproject: '/atlproject',
        atltemplate: '/atltemplate',
        templatedetail: '/templatedetail',
        selectTemplate: '/selectTemplate',
        templatedetails: '/templatedetails',
    },
    activate: '/activate',
    project: {
        list: '/project',
        detail: '/project/detail',
        review: '/project/review',
        edit: '/project/edit',
    },


    yueling: {
        list: '/yueling',
        detail: '/yueling',
    },
    institution: {
        index: '/institution',
        list: '/institution',
        institutionPermissions: "/institutionPermissions",
        reviewedList: '/institution/reviewed_list',
        reviewedDetail: '/institution/reviewed_detail'
    },
    ad: {
        list: '/ad',
    },
    user: {
        info: '/user/info',
        institution: '/user/institution',
        list: '/user',
        qualification: '/user/qualification',
        detail: '/user/detail',
        password: '/user/password',
        limits: '/userlimits',
        limitsedit: '/userlimitsedit',
    },
    message: {
        list: '/message',
    },
    troup: {
        guru: {
            list: '/troup/guru/list',
            detail: '/troup/guru/detail'
        },
        reserve: {
            list: '/troup/reserve/list',
            detail: '/troup/reserve/detail',
            edit: '/troup/reserve/edit'
        },
        group: {
            list: '/troup/group/list',
            detail: '/troup/group/detail'
        },
        sign: {
            list: '/troup/sign/list',
        },
        download: {
            list: '/troup/download/list'
        }
    },
    activityInfo: {
        list: '/activity_info/list',
        edit: '/activity_info/edit',
        detail: '/activity_info/detail'
    },
    groupInfo: {
        list: '/group_info/list',
        edit: '/group_info/edit',
        detail: '/group_info/detail'
    },
    orgInfo: {
        list: '/org_info/list',
        edit: '/org_info/edit',
        detail: '/org_info/detail'
    },
    artInfo: {
        list: '/art_info/list',
        edit: '/art_info/edit',
        detail: '/art_info/detail'
    },
};
export default PATH;
