import React, {Component} from 'react';

import LoginForm from '../components/user/LoginForm';
import LoginForm2 from '../components/user/LoginForm2';
import styles from './index.module.css';
import LoginOrganization from '../components/user/LoginOrganization';
import LoginFormSetPassword from '../components/user/LoginFormSetPassword';
import {Tabs} from 'antd';
import {doCheckUpgrade} from "./common/Upgrade";

const TabPane = Tabs.TabPane;
type HomeState = {};
export default class Home extends Component<{}, HomeState> {
    state = {
        showmodel: false,
        list: [],
        userData: {},
        showSetPassWord: false,
    };

    componentDidMount() {
        doCheckUpgrade()
    }

    changeStaus = (showmodel: boolean, userData: any) => {
        if (!userData) {
            this.setState({
                showmodel: true,
                showSetPassWord: false,
            });
        } else {
            this.setState({
                list: userData.userOrganizationSelectVos,
                userData: userData.user,
            });
            if (userData.errorCode === 'U0016') {
                // '没有设置登录密码';
                this.setState({
                    showSetPassWord: true,
                });
            } else {

                this.setState({
                    showmodel: showmodel,
                    showSetPassWord: false,
                });
            }
        }
    };

    render() {
        const {list, userData, showSetPassWord} = this.state;
        return (
            <>
                <div className={styles.container}>
                    {showSetPassWord ? (
                        <LoginFormSetPassword
                            changeStaus={this.changeStaus}
                            userData={userData}
                        ></LoginFormSetPassword>
                    ) : this.state.showmodel ? (
                        <LoginOrganization
                            list={list}
                            changeStaus={this.changeStaus}
                            userData={userData}
                        ></LoginOrganization>
                    ) : (
                        <main className={styles.main}>
                            <div className={styles.title}>
                                <div>
                                    <img className={styles.logoImg}
                                         src={"https://appcdn.lnyst.com/static/logo2.png"}/></div>
                                <div>中国老年艺术团</div>
                            </div>
                            <div className={styles.grid}>
                                <Tabs className={styles.tab} defaultActiveKey="1">
                                    <TabPane
                                        tab={<span className={styles.tabspan}>短信登录</span>}
                                        key="1"
                                    >
                                        <LoginForm changeStaus={this.changeStaus}/>
                                    </TabPane>
                                    <TabPane
                                        tab={<span className={styles.tabspan}>密码登录</span>}
                                        key="2"
                                    >
                                        <LoginForm2 changeStaus={this.changeStaus}/>
                                    </TabPane>
                                </Tabs>
                            </div>

                        </main>
                    )}

                </div>
                <a href="https://beian.miit.gov.cn/" style={{
                    position: 'fixed',
                    bottom: 20,
                    textAlign: "center",
                    width: '100%',
                    color: '#666'
                }} target="_blank">京ICP备2023025142号-2</a>
            </>
        );
    }

}
