export const addParams = (field: string, value: any, type: any) => {

    if (value) {
        return `&${field}=${value}`
    } else {
        if (type === NUMBER_STR) {
            return `&${field}=0`
        } else if (type === STRING_STR) {
            return `&${field}=`
        } else if (type === TIME_STR) {
            return '';
        }
    }
}

export const STRING_STR = 'string'

export const NUMBER_STR = 'number'

export const TIME_STR = 'TIME_STR';


export type BasePageData = {
    // 基础属性
};

export type BasePageType = {
    searchData: { page: number | 0 },
    pageSize: number | 0,
    totalElements: number | 0,
    totalPages: number | 0,
    btnQ: {},
    content: [],
    importLoading: boolean | undefined,
    tip: string | undefined,
    selectId: number | 0
}

export const BasePageDate: BasePageType = {
    searchData: {page: 0},
    pageSize: 10,
    totalElements: 0,
    totalPages: 0,
    btnQ: {},
    content: [],
    importLoading: undefined,
    tip: undefined,
    selectId: 0
}