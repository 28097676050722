import React from 'react';
import {Button, DatePicker, Divider, Flex, Input, message, Modal, Select, Space, Spin, Table, TableProps} from 'antd';
import API from '../../common/api';
import {ColumnsType} from 'antd/es/table';
import 'dayjs/locale/zh-cn';
import App from '../../../App';
import BaseListPage from "../../common/listpage/BaseListPage";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {BasePageType} from "../../common/listpage/BaseListPageType";
import {Link} from "react-router-dom";
import encryptData from "../../common/crypto";
import {addParams, BasePageDate, NUMBER_STR, STRING_STR, TIME_STR} from "../../common/listpage/ListCommon";
import {genderArr} from "../../common/DictCommon";
import Fetch from "../../common/FetchIt";
import {ReserveRongTitleArr, troupeStatusArr, TroupeStatusSelect} from "../util/TroupeArtistUtil";
import TroupTransferGroupModelImport from "./TroupTransferGroupModel";
import createModal from "../../common/createModal";
import locale from 'antd/lib/date-picker/locale/zh_CN';
import {Dayjs} from "dayjs";

import {DownloadOutlined} from '@ant-design/icons';
import {MenuRole} from "../../common/MenuRole";

const {RangePicker} = DatePicker;

const TroupTransferGroupModel = createModal(TroupTransferGroupModelImport);
type TableRowSelection<T extends object = object> = TableProps<T>['rowSelection'];

type User = { name: string | '', msg: string | '', id: number | 0, troupeStatus: number | 0 };
type HospitalPatientType = BasePageType & {
    searchData: {
        name: undefined,
        phone: undefined,
        groupName: undefined,
        rongTitle: undefined,
        createDate: [Dayjs | null, Dayjs | null],
        troupeStatus: undefined,
        page: 0
    }
    btnQ: MenuRole,
    statusListArr: any[] | undefined,
    selectedRowKeys: React.Key[],
    fixedTop: boolean
}
const defData: HospitalPatientType = {
    ...BasePageDate,
    searchData: {
        name: undefined,
        phone: undefined,
        groupName: undefined,
        rongTitle: undefined,
        createDate: [null, null],
        troupeStatus: undefined,
        page: 0
    },
    btnQ: {},
    statusListArr: [],
    selectedRowKeys: [],
    fixedTop: true
}

const confirm = Modal.confirm;

class TroupReserveListClass extends BaseListPage {

    private troupTransferGroupModelResult: any = null;

    state: HospitalPatientType = {
        ...defData,
        btnQ: {}
    };

    componentDidMount() {
        // BoneSelectStatus([105, 106, 107], this)
        super.componentDidMount();
    }

    loadData = () => {
        const {pageSize, searchData} = this.state;
        const startTime = searchData.createDate[0]?.format('YYYY-MM-DD')
        const endTime = searchData.createDate[1]?.format('YYYY-MM-DD')
        let url = `${API.troup.guru.query_group_page}?page=${searchData.page}&size=${pageSize}`;
        url = url + addParams("name", searchData.name, STRING_STR);
        url = url + addParams("phone", searchData.phone, STRING_STR);
        url = url + addParams("groupName", searchData.groupName, STRING_STR);
        url = url + addParams("startTime", startTime, TIME_STR);
        url = url + addParams("endTime", endTime, TIME_STR);
        url = url + addParams("rongTitle", searchData.rongTitle, STRING_STR);
        url = url + addParams("troupeStatus", searchData.troupeStatus, NUMBER_STR);
        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                importLoading: false
            });
        })
    };


    changeCondition = (condition: any) => {
        const {searchData} = this.state;
        this.setState({
            searchData: {...searchData, ...condition},
        });
    };

    reset = () => {
        super.reset(defData.searchData);
    };

    handleKeyDown = (e: any) => {
        super.handleKeyDown(e);
    }

    closeModal = () => {

        this.loadData();
    }

    approved = (e: any) => {

        const that = this;
        confirm({
            title: `是否审核通过`,
            onOk() {
                Fetch.putJSON(API.troup.guru.approved, {id: e}).then(() => {
                    message.success('提交成功');
                    that.setState({
                        selectedRowKeys: []
                    })
                    that.loadData();
                });
            }
        })
    }

    download = () => {

        const that = this;
        const {searchData} = this.state;
        confirm({
            title: `是否确认下载`,
            onOk() {
                if (that.isAnyValueNotEmpty(searchData)) {
                    const startTime = searchData.createDate[0]?.format('YYYY-MM-DD')
                    const endTime = searchData.createDate[1]?.format('YYYY-MM-DD')
                    let params = {
                        ...searchData,
                        createDate: [],
                        startTime: startTime,
                        endTime: endTime
                    }
                    Fetch.putJSON(API.troup.guru.do_download, params).then(() => {
                        message.success('提交成功');
                    });
                } else {
                    message.success('请选择条件');
                }
            }
        })
    }

    goDownload = () => {
        window.location.href = '/#/troup/download/list';
    }

    isEmptyValue = (value: any) => {

        if (value === null || value === undefined || value === '' || value === 0) {
            return true;
        }
        if (Array.isArray(value)) {
            if (value.length === 0) return
            if (value.length > 0) {
                let result = true;
                value.forEach((item) => {
                    console.log(!(item === null || item === undefined))
                    if (!(item === null || item === undefined)) {
                        if (result) {
                            result = false;
                        }
                    }
                })
                return result;
            }
        }
        return false;
    };

    isAnyValueNotEmpty = (obj: any) => {

        console.log(obj)
        return Object.values(obj).some((value) => {
            return !this.isEmptyValue(value)
        })
    };

    batchApproved = () => {

        const {selectedRowKeys} = this.state;
        if (!selectedRowKeys) {
            message.success('请选择需要通过的团员');
        }
        const that = this;
        confirm({
            title: `是否审核通过`,
            onOk() {
                that.doLoading();
                let params: any[] = [];
                selectedRowKeys.forEach((item) => {
                    params.push({id: item});
                })
                Fetch.putJSON(API.troup.guru.batch_approved, params).then(() => {
                    message.success('提交成功');
                    that.hideLoading();
                    that.loadData();
                    that.setState({
                        selectedRowKeys: []
                    })
                }).catch(e => {
                    that.hideLoading();
                });
            }
        })
    }

    deletedReserve = (id: number) => {

        const that = this;
        confirm({
            title: `是否确认删除？`,
            onOk() {
                that.doLoading()
                Fetch.postJSON(API.troup.guru.deleted_by_id, {id: id}).then(() => {
                    message.success('提交成功');
                    that.setState({
                        importLoading: false,
                        selectedRowKeys: []
                    })
                    that.loadData();
                }).catch(e => {
                    that.setState({
                        importLoading: false
                    })
                });
            }
        })
    }

    transferGroup = (id: number) => {

        this.setState({
            selectId: id,
        }, () => {
            this.troupTransferGroupModelResult.show();
        })
    }

    onSelectChange = (newSelectedRowKeys: React.Key[]) => {

        this.setState({
            selectedRowKeys: newSelectedRowKeys
        })
    };

    render() {
        const {
            pageSize, totalElements,
            btnQ, searchData, content,
            selectedRowKeys,
            fixedTop
        } = this.state;

        const rowSelection: TableRowSelection<User> = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            selections: [
                Table.SELECTION_ALL,
                Table.SELECTION_INVERT,
                Table.SELECTION_NONE,
            ],
            getCheckboxProps: (record: User) => ({
                disabled: record.troupeStatus !== 1, // Column configuration not to be checked
            }),
            columnWidth: 100
        };
        const columns: ColumnsType<User> = [

            {
                title: '姓名',
                dataIndex: 'name',
                align: `center`,
            },
            {
                title: '所在团队',
                dataIndex: 'groupName',
                align: `center`,
            },
            {
                title: '性别',
                dataIndex: 'gender',
                align: `center`,
                render(value: any, record: any) {
                    return <>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            {genderArr[value]}
                        </div>
                    </>;
                },
            },
            {
                title: '年龄',
                dataIndex: 'age',
                align: `center`,
            },
            {
                title: '联系电话',
                dataIndex: 'phone',
                align: `center`,
            },
            {
                title: '生日',
                dataIndex: 'birthday',
                align: `center`,
            },
            {
                title: '团员审核状态',
                dataIndex: 'troupeStatus',
                align: `center`,
                render(value: any, record: any) {
                    return <>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            {troupeStatusArr[value]}
                        </div>
                    </>;
                },
            },
            {
                title: '提交时间',
                dataIndex: 'createdDate',
                align: `center`,
            },
            {
                title: '操作',
                align: `center`,
                dataIndex: 'status',
                render: (value: any, record: any) => {
                    return (
                        <Space split={<Divider type="vertical"/>}>
                            {(btnQ.R272) && (
                                <Button danger type={"link"} onClick={() => this.deletedReserve(record.id)}>
                                    删除
                                </Button>
                            )}
                            {(btnQ.R274) && (
                                <Link to={`/troup/reserve/edit?id=${encryptData(record.id + "")}`}>
                                    编辑
                                </Link>
                            )}
                            {(btnQ.R271) && (
                                <Button type={"link"} onClick={() => this.transferGroup(record.id)}>
                                    调整团队
                                </Button>
                            )}
                            {(btnQ.R269) && (
                                <>
                                    {(record.troupeStatus === 1) && (
                                        <Button type={"link"} onClick={() => this.approved(record.id)}>
                                            审核通过
                                        </Button>
                                    )}
                                </>
                            )}
                            {(btnQ.R257) && (
                                <Link to={`/troup/reserve/detail?id=${encryptData(record.id + "")}`}>
                                    详情
                                </Link>
                            )}
                        </Space>
                    );
                },
            },
        ];
        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Flex justify={"space-between"}>
                            <Space onKeyDown={this.handleKeyDown} wrap>
                                <Input
                                    placeholder="姓名"
                                    value={searchData.name}
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, name: e.target.value})
                                    }
                                    }
                                />
                                <Input
                                    placeholder="手机号"
                                    value={searchData.phone}
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, phone: e.target.value})
                                    }
                                    }
                                />
                                <Input
                                    placeholder="所在团队"
                                    value={searchData.groupName}
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, groupName: e.target.value})
                                    }
                                    }
                                />
                                <RangePicker
                                    locale={locale}
                                    value={searchData.createDate}
                                    onChange={e => this.changeCondition({createDate: e, page: 0})}
                                />
                                <Select
                                    style={{width: 220}}
                                    placeholder="艺术团荣称"
                                    optionFilterProp="children"
                                    options={ReserveRongTitleArr}
                                    value={searchData.rongTitle}
                                    allowClear={true}
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, rongTitle: e})
                                    }}
                                />
                                <Select
                                    style={{width: 220}}
                                    placeholder="审核状态"
                                    optionFilterProp="children"
                                    options={TroupeStatusSelect}
                                    value={searchData.troupeStatus}
                                    allowClear={true}
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, troupeStatus: e})
                                    }}
                                />
                                <Button onClick={this.loadData}>
                                    搜索
                                </Button>
                                <Button onClick={() => this.reset()}>重置</Button>
                                {(btnQ.R270) && (
                                    <Button onClick={() => this.download()}> <DownloadOutlined/>下载</Button>
                                )}
                            </Space>
                            {(btnQ.R270) && (
                                <Button onClick={() => this.goDownload()}>下载历史</Button>
                            )}
                        </Flex>
                        <Table
                            // loading={this.state.loading}
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            summary={() => (
                                <>
                                    {(selectedRowKeys.length > 0) && (
                                        <Table.Summary fixed={fixedTop ? 'top' : 'bottom'}>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell index={0} colSpan={9}>
                                                    <Button onClick={() => this.batchApproved()}>
                                                        批量通过
                                                    </Button>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        </Table.Summary>
                                    )}
                                </>
                            )}
                            rowSelection={rowSelection}
                            onChange={(pagination: any) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1
                                    }
                                )
                            }
                        />
                    </div>
                </Spin>
                <TroupTransferGroupModel
                    width={500}
                    title={'调整团队'}
                    id={this.state.selectId}
                    onCancel={this.closeModal}
                    ref={(c) => (this.troupTransferGroupModelResult = c)}
                />
            </>
        );
    }

}

export default function TroupReserveList() {

    return (

        <App selectedKeys={
            ['TroupReserveList']
        }
             openKeys="TROUP">
            <TroupReserveListClass/>
        </App>
    );
}
