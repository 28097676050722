export type TroupeArtistDetailType = {
    id: Number | null,
    importLoading: boolean,
    data: {
        name: string | null,
        age: number | null,
        gender: string | number | 0
        birthday: string | null,
        idNumber: string | null,
        phone: string | null,
        address: string | null,
        signUrl: {
            url: string
        } | null,
        origin: string | null,
        ethnic: string | null,
        politics: string | null,
        specialtyStr: string | null,
        groupName: string | null,
        groupDuties: string | null,
        originalUnit: string | null,
        originalDuties: string | null,
        signDate: string | null,
        certificateDate: string | null,
        rongTitle: string | null,
        identity: string | null,
        files: any[],
        certificate: { url: string } | null
    }
}

export function getInitDetail() {
    return {
        id: null,
        importLoading: true,
        data: {
            name: null,
            age: null,
            gender: 0,
            birthday: null,
            idNumber: null,
            phone: null,
            address: null,
            signUrl: null,
            origin: null,
            ethnic: null,
            politics: null,
            specialtyStr: null,
            groupName: null,
            groupDuties: null,
            originalUnit: null,
            originalDuties: null,
            signDate: null,
            certificateDate: null,
            rongTitle: null,
            identity: null,
            files: [],
            certificate: null
        }
    }
}

export type TroupeArtistEditType = {
    id: Number | null,
    importLoading: boolean,
    token: string,
    data: {
        name: string | null,
        age: number | null,
        gender: string | number | 0
        birthday: string | null,
        idNumber: string | null,
        phone: string | null,
        address: string | null,
        signUrl: {
            url: string
        } | null,
        origin: string | null,
        ethnic: string | null,
        politics: string | null,
        specialtyStr: string | null,
        groupName: string | null,
        groupDuties: string | null,
        originalUnit: string | null,
        originalDuties: string | null,
        signDate: string | null,
        certificateDate: string | null,
        rongTitle: string | null,
        identity: string | null,
        files: any[],
        certificate: { url: string } | null,
        dictInfo: { politics: any[], nationality: any[] }
        provinceId: number | 0,
        cityId: number | 0,
        districtId: number | 0,
        originProvinceId: number | 0,
        originCityId: number | 0,
        originDistrictId: number | 0,
    },
    addressStr: string | null,
    originStr: string | null
}


export function getInitEdit() {
    return {
        id: null,
        importLoading: true,
        token: '',
        data: {
            name: null,
            age: null,
            gender: 0,
            birthday: null,
            idNumber: null,
            phone: null,
            address: null,
            signUrl: null,
            origin: null,
            ethnic: null,
            politics: null,
            specialtyStr: null,
            groupName: null,
            groupDuties: null,
            originalUnit: null,
            originalDuties: null,
            signDate: null,
            certificateDate: null,
            rongTitle: null,
            identity: null,
            files: [],
            certificate: null,
            dictInfo: {politics: [], nationality: []},
            provinceId: 0,
            cityId: 0,
            districtId: 0,
            originProvinceId: 0,
            originCityId: 0,
            originDistrictId: 0,
        },
        addressStr: null,
        originStr: null
    }
}