import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {HashRouter, Route, Switch} from 'react-router-dom';

import './styles/globals.css';
import './styles/app.css';
// import 'antd/dist/antd.css';
import 'braft-editor/dist/index.css';
import './components/common/editor/editor.css';

import reportWebVitals from './reportWebVitals';

import {ConfigProvider} from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import PATH from './components/common/pagePath';
import Home from './components/index';
import 'dayjs/locale/zh-cn';
import dayjs from "dayjs";
import SettingIndex from './components/user/UserSettingForm';
import PasswordIndex from './components/user/PasswordForm';
import UserIndex from './components/user/UserList';
import ActivityInfoListPage from './components/display/activityInfo/ActivityInfoList';
import YuelingIndex from './components/apply/ApplyList';
import UserDetailIndex from './components/user/UserDetail';
import InstitutionIndex from './components/institution/InstitutionList';
import InstitutionPermissions from './components/institution/InstitutionPermissions';
import InstitutionAuditList from './components/institution/audit/InstitutionAuditList'
import InstitutionAuditDetail from './components/institution/audit/InstitutionAuditDetail'
import QualificationIndex from './components/user/QulificationForm';
import InstitutionSettingIndex from './components/user/InstitutionSettingForm';
import MessageIndex from './components/message/MessageList';
import AdIndex from './components/ad/AdList';
import ADetail from './components/apply/ApplyDetail';
import ActivityInfoFormPage from './components/display/activityInfo/ActivityInfoForm';
import InstitutionDetailIndex from './components/institution/InstitutionDetail';
// 账号管理
import AccountList from './components/account/accountList';
// 账户激活
import Activate from './components/activate/indexForm';
import Limits from './components/user/limits';
import LimitsEdit from './components/user/limitsEdit';
import NotFound from './components/404/NotFound';

import TroupGuruListListPage from "./components/troup/guru/TroupGuruList";
import TroupArtistDetailPage from "./components/troup/artistdetail/TroupArtistDetail";
import TroupReserveListPage from "./components/troup/reserve/TroupReserveList";
import TroupArtistGroupDetailPage from "./components/troup/artistdetail/TroupArtistGroupDetail";
import TroupGroupListPage from "./components/troup/group/TroupGroupList";
import TroupSignListPage from "./components/troup/sign/TroupSignList";
import TroupGroupDetailPage from "./components/troup/group/TroupGroupDetail";
import ActivityInfoDetailPage from "./components/display/activityInfo/ActivityInfoDetail";

import GroupInfoDetailPage from "./components/display/groupinfo/GroupInfoDetail";
import GroupInfoFormPage from "./components/display/groupinfo/GroupInfoForm";
import GroupInfoListPage from "./components/display/groupinfo/GroupInfoList";

import OrgInfoDetailPage from "./components/display/orginfo/OrgInfoDetail";
import OrgInfoFormPage from "./components/display/orginfo/OrgInfoForm";
import OrgInfoListPage from "./components/display/orginfo/OrgInfoList";

import ArtInfoDetailPage from "./components/display/artinfo/ArtInfoDetail";
import ArtInfoFormPage from "./components/display/artinfo/ArtInfoForm";
import ArtInfoListPage from "./components/display/artinfo/ArtInfoList";
import TroupDownloadListPage from "./components/troup/download/TroupDownloadList";
import TroupeReserveEditPage from "./components/troup/reserve/TroupeReserveEdit";


dayjs.locale('zh-cn');

ReactDOM.render(
    <React.StrictMode>
        <ConfigProvider locale={zhCN}>
            {/* @ts-ignore */}
            <HashRouter>
                <Suspense fallback={<div>出错了</div>}>
                    <Switch>
                        <Route path={`${PATH.troup.reserve.edit}`} exact>
                            <TroupeReserveEditPage/>
                        </Route>
                        <Route path={`${PATH.troup.download.list}`} exact>
                            <TroupDownloadListPage/>
                        </Route>
                        <Route path={`${PATH.artInfo.detail}`} exact>
                            <ArtInfoDetailPage/>
                        </Route>
                        <Route path={`${PATH.artInfo.edit}`} exact>
                            <ArtInfoFormPage/>
                        </Route>
                        <Route path={`${PATH.artInfo.list}`} exact>
                            <ArtInfoListPage/>
                        </Route>

                        <Route path={`${PATH.orgInfo.detail}`} exact>
                            <OrgInfoDetailPage/>
                        </Route>
                        <Route path={`${PATH.orgInfo.edit}`} exact>
                            <OrgInfoFormPage/>
                        </Route>
                        <Route path={`${PATH.orgInfo.list}`} exact>
                            <OrgInfoListPage/>
                        </Route>
                        <Route path={`${PATH.groupInfo.detail}`} exact>
                            <GroupInfoDetailPage/>
                        </Route>
                        <Route path={`${PATH.groupInfo.edit}`} exact>
                            <GroupInfoFormPage/>
                        </Route>
                        <Route path={`${PATH.groupInfo.list}`} exact>
                            <GroupInfoListPage/>
                        </Route>
                        <Route path={`${PATH.activityInfo.detail}`} exact>
                            <ActivityInfoDetailPage/>
                        </Route>
                        <Route path={`${PATH.troup.group.detail}`} exact>
                            <TroupGroupDetailPage/>
                        </Route>
                        <Route path={`${PATH.troup.sign.list}`} exact>
                            <TroupSignListPage/>
                        </Route>
                        <Route path={`${PATH.troup.group.list}`} exact>
                            <TroupGroupListPage/>
                        </Route>
                        <Route path={`${PATH.troup.reserve.detail}`} exact>
                            <TroupArtistGroupDetailPage/>
                        </Route>
                        <Route path={`${PATH.troup.reserve.list}`} exact>
                            <TroupReserveListPage/>
                        </Route>
                        <Route path={`${PATH.troup.guru.detail}`} exact>
                            <TroupArtistDetailPage/>
                        </Route>
                        <Route path={`${PATH.troup.guru.list}`} exact>
                            <TroupGuruListListPage/>
                        </Route>
                        <Route path={PATH.institution.reviewedList} exact>
                            <InstitutionAuditList/>
                        </Route>
                        <Route path={`${PATH.institution.reviewedDetail}/:id`} exact>
                            <InstitutionAuditDetail/>
                        </Route>
                        <Route path={PATH.login} exact>
                            <Home/>
                        </Route>

                        {/* 组织激活 */}
                        <Route path={PATH.activate} exact>
                            <Activate/>
                        </Route>
                        <Route path={PATH.account.list}>
                            <AccountList/>
                        </Route>

                        <Route path={PATH.activityInfo.list} exact>
                            <ActivityInfoListPage/>
                        </Route>
                        <Route path={PATH.activityInfo.edit} exact>
                            <ActivityInfoFormPage/>
                        </Route>

                        <Route path={PATH.yueling.list} exact>
                            <YuelingIndex/>
                        </Route>
                        <Route path={`${PATH.yueling.detail}/:id`}>
                            <ADetail/>
                        </Route>
                        <Route path={PATH.institution.list} exact>
                            <InstitutionIndex/>
                        </Route>
                        <Route path={`${PATH.institution.index}/:id`} exact>
                            <InstitutionDetailIndex/>
                        </Route>
                        <Route path={PATH.user.info}>
                            <SettingIndex/>
                        </Route>
                        <Route path={PATH.user.institution}>
                            <InstitutionSettingIndex/>
                        </Route>
                        <Route path={PATH.user.list} exact>
                            <UserIndex/>
                        </Route>
                        <Route path={PATH.user.qualification}>
                            <QualificationIndex/>
                        </Route>
                        <Route path={`${PATH.user.detail}/:id`}>
                            <UserDetailIndex/>
                        </Route>
                        <Route path={PATH.user.password}>
                            <PasswordIndex/>
                        </Route>
                        <Route path={PATH.user.limits}>
                            <Limits/>
                        </Route>
                        <Route path={PATH.user.limitsedit}>
                            <LimitsEdit/>
                        </Route>
                        <Route path={PATH.message.list} exact>
                            <MessageIndex/>
                        </Route>
                        <Route path={PATH.ad.list} exact>
                            <AdIndex/>
                        </Route>

                        <Route path={PATH.institution.institutionPermissions} exact>
                            <InstitutionPermissions/>
                        </Route>
                        <Route path="*">
                            <NotFound/>
                        </Route>
                    </Switch>
                </Suspense>
            </HashRouter>
        </ConfigProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
