import React, {useEffect, useState} from 'react';
import {Button, Form, FormInstance, message, Modal, Select, Space} from 'antd';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";
import FormItem from 'antd/lib/form/FormItem';

const {confirm} = Modal;

type CreateCertificateType = {
    id: number,
    hide: any,
    reload: any,
    data: { founded: any }
}

export default function TroupTransferGroupModel(props: CreateCertificateType) {

    const reason = props.id;
    const formRef = React.createRef<FormInstance>();
    const [groupId, setGroupId] = useState<number | null>(null);
    const [groupSelect, setGroupSelect] = useState<[]>([]);

    const cancel = () => {
        props.hide();
    }
    const ok = () => {
        props.hide();
        props.reload();
    }

    const create = () => {
        Fetch.postJSON(API.troup.guru.transfer_group, {id: props.id, groupId: groupId}).then(() => {
            message.success('提交成功');
            ok();
        });
    }

    const changeGroup = (e: any) => {

        setGroupId(e);
    }

    const onSearch = (e: any) => {

        Fetch.get(`${API.troup.group.admin_group_select}?name=${e}`).then((data) => {
            setGroupSelect(data);
        });
    }

    useEffect(() => {

    }, []);


    return <>

        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
            <Form
            >
                <FormItem
                    name="groupName"
                    label="社团名称"
                    wrapperCol={{span: 17}}
                    labelCol={{span: 4}}
                    rules={[{required: true, message: '必填'}]}
                >
                    <Select
                        showSearch
                        placeholder="请选择团队"
                        optionFilterProp="label"
                        onChange={changeGroup}
                        onSearch={onSearch}
                        options={groupSelect}
                        style={{width: "100%"}}
                    />
                </FormItem>
                <Space>
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="middle"
                        onClick={create}
                    >
                        保存
                    </Button>
                    <Button
                        size='middle'
                        style={{marginRight: '15px'}}
                        onClick={cancel}
                    >
                        取消
                    </Button>
                </Space>
            </Form>
        </Space>
    </>;
}
