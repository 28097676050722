import React from 'react';
import {GetListStorage, SetListStorage} from "./SessionStorageUtil";
import Fetch from "../FetchIt";

class BaseListPage extends React.Component<{}, {}> {

    state = {
        searchData: {page: 0},
        pageSize: 10,
        totalElements: 0,
        totalPages: 0,
        btnQ: {}
    }

    componentDidMount() {
        const listStorage = GetListStorage();
        const {searchData} = this.state;
        this.setState({
            searchData: {
                ...searchData,
                ...listStorage
            }
        }, () => {
            let btnQ = window.sessionStorage.getItem('buttons');
            let btn = {};
            if (btnQ) {
                btn = JSON.parse(btnQ);
                this.setState({
                    btnQ: btn['buttons'],
                });
            }
            this.loadData();
        })
    };

    loadData() {
        throw new Error('Subclasses must override doSomething method.');
    }

    pageChange(condition: any) {

        const {searchData} = this.state;
        this.setState({
            searchData: {
                ...searchData, ...condition
            }
        }, () => {
            this.loadData();
        });
    };

    handleKeyDown(e: any) {

        if (e.key === 'Enter') {
            this.loadData();
        }
    };

    protected changeCondition(condition: any) {
        const {searchData} = this.state;
        this.setState({
            searchData: {...searchData, ...condition},
        });
    };

    protected doLoadData(url: string, callback: { (data: any): void; (arg0: any): any; }) {

        const {searchData} = this.state;
        SetListStorage(searchData)
        Fetch.get(
            url
        ).then((data) => {
            callback && callback(data);
        });
    }

    protected reset(def: any) {

        this.setState({
            searchData: def,
        }, () => {
            this.loadData();
        })
    };

    protected doLoading() {
        this.setState({
            importLoading: true
        })
    }

    protected hideLoading() {
        this.setState({
            importLoading: true
        })
    }
}

export default BaseListPage;
