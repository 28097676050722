import React, {Component} from 'react';
import {Breadcrumb, Button, Drawer, Form, FormInstance, Input, message, Space,} from 'antd';
import {Link} from 'react-router-dom';
import Fetch from '../../common/FetchIt';
import Editor from '../../common/editor/Editor';
import API from '../../common/api';
import styles from './project.module.css';
import {Project, readFile} from './types';
import FileUploader from '../../common/uploader';
import App from '../../../App';
import common from '../../common/common';
import {decryptData} from "../../common/crypto";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";

const FormItem = Form.Item;

class GroupInfoFormClass extends Component {
    formRef = React.createRef<FormInstance>();
    draft = 'PUBLISHED';
    loading = false;
    state = {
        id: 0,
        project: {},
        visible: false,
        value1: '',
        value2: '',
        date: '',
        value3: '',
    };

    componentDidMount() {
        const id = decryptData(common.getQuery('id'));
        if (id) {
            this.setState(
                {
                    id: decryptData(common.getQuery('id'))
                },
                () => {
                    this.loadData();
                }
            );
        }
    }

    loadData = () => {
        Fetch.get(`${API.group_info.get_detail}?id=${this.state.id}`).then((data) => {

            readFile(data.contentUrl).then((res) => {
                data.content = res;
                data.cover = [{
                    id: data.cover,
                    uid: data.cover,
                    status: 'done',
                    name: '封面图',
                    url: API.domain + data.coverUrl,
                }];
                this.setState({...(data)},
                    () => {
                        this.formRef.current?.setFieldsValue(data);
                    }
                );
            })
        });
    };
    format = (dat: any) => {
        //获取年月日，时间
        var year = dat.getFullYear();
        var mon =
            dat.getMonth() + 1 < 10 ? '0' + (dat.getMonth() + 1) : dat.getMonth() + 1;
        var data = dat.getDate() < 10 ? '0' + dat.getDate() : dat.getDate();
        var hour = dat.getHours() < 10 ? '0' + dat.getHours() : dat.getHours();
        var min = dat.getMinutes() < 10 ? '0' + dat.getMinutes() : dat.getMinutes();
        // var seon =
        //   dat.getSeconds() < 10 ? '0' + dat.getSeconds() : dat.getSeconds();

        var newDate = year + '-' + mon + '-' + data + ' ' + hour + ':' + min;
        return newDate;
    };

    publish = () => {
        this.draft = 'PUBLISHED';
        this.formRef.current?.submit();
    };

    submit = (values: any) => {

        if (this.loading) {
            return;
        }
        values.cover = values.cover.map((item: any) => item.id).join(',');
        values.content = values.content.toHTML
            ? values.content.toHTML()
            : values.content;
        if (values.content == '<p></p>') {
            message.error('内容不能为空！');
            return;
        }
        this.loading = true;
        console.log(this.state.id)
        if (this.state.id) {
            values.id = this.state.id;
            this.update(values);
        } else {
            this.create(values);
        }
    };

    create = (values: Project) => {
        let that = this;
        Fetch.postJSON(`${API.group_info.do_create}`, values)
            .then(() => {
                that.loading = false;
                message.success('提交成功');
                window.history.back();
            })
            .catch((err) => {
                that.loading = false;
            });
    };

    update = (values: Project) => {
        console.log(values)
        Fetch.putJSON(`${API.group_info.do_update}`, values).then(() => {
            this.loading = false;
            message.success('保存成功');
            // this.props.hide();
            // this.props.reload();
            window.history.back();
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    cancel = () => {
        window.history.go(-1);
    }

    render() {
        // const id = this.props.project && this.props.project.id;
        const {id, project} = this.state;

        return (
            <>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>
                        <Link to="/project">公益资讯</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{id ? '修改资讯' : '新建资讯'}</Breadcrumb.Item>
                </Breadcrumb>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <div className={styles.container}>
                        <Form
                            initialValues={project}
                            onFinish={this.submit}
                            ref={this.formRef}
                        >
                            <FormItem hidden noStyle name="id">
                                <Input/>
                            </FormItem>
                            <FormItem hidden noStyle name="status">
                                <Input/>
                            </FormItem>
                            <FormItem hidden noStyle name="clicks">
                                <Input/>
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                name="cover"
                                valuePropName="fileList"
                                label="封面图"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 20}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <FileUploader
                                    multiple
                                    maxCount={1}
                                    listType="picture-card"
                                    checkSize={false}
                                    desc="支持扩展名：.jpg、.png; 图片大小不能超过2M; 建议尺寸 224 * 160; 最多6张"
                                />
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                name="title"
                                label="标题"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 20}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <Input placeholder="标题"/>
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                name="subTitle"
                                label="副标题"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 20}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <Input placeholder=""/>
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                name="content"
                                label="内容"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 20}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <Editor url={API.upload}/>
                            </FormItem>
                            <FormItem wrapperCol={{span: 12, offset: 6}} className="center">
                                <Space>
                                    <Button
                                        type="primary"
                                        onClick={this.publish}
                                        className={styles.mybtn}
                                    >
                                        提交
                                    </Button>
                                    <Button
                                        onClick={this.cancel}
                                        className={styles.mybtn}
                                    >
                                        返回
                                    </Button>
                                </Space>
                            </FormItem>
                        </Form>
                        <div>
                            <Drawer
                                title="小程序预览"
                                width={360}
                                placement="right"
                                closable={false}
                                onClose={this.onClose}
                                open={this.state.visible}
                            >
                                <div className={styles.phonebox}>
                                    <div className={styles.phoneboxContent}>
                                        <div className={styles.phoneboxTitle}>
                                            {this.state.value1}
                                        </div>
                                        <p className={styles.dateC}>{this.state.date} 浏览量 0</p>
                                        <div
                                            dangerouslySetInnerHTML={{__html: this.state.value3}}
                                        ></div>
                                    </div>
                                </div>
                            </Drawer>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default function GroupInfoForm() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]} openKeys={pageKey.openKeys}>
            <GroupInfoFormClass/>
        </App>
    );
}
