import React from 'react';
import {Avatar, Button, Divider, Flex, Input, message, Modal, Select, Space, Spin, Table} from 'antd';
import {addParams, BasePageDate, BasePageType, NUMBER_STR, STRING_STR} from "../../common/listpage/ListCommon";
import BaseListPage from "../../common/listpage/BaseListPage";
import API from "../../common/api";
import Api from "../../common/api";
import {ColumnsType} from 'antd/es/table';
import {Link} from "react-router-dom";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import encryptData from "../../common/crypto";
import App from "../../../App";
import {AntDesignOutlined} from "@ant-design/icons";
import Fetch from "../../common/FetchIt";
import {DisplayStatusObj} from "../DisplayUtail";
import {MenuRole} from "../../common/MenuRole";

const confirm = Modal.confirm;

type User = { name: string | '', msg: string | '', id: number | 0 };

type PageType = BasePageType & {
    searchData: {
        title: undefined,
        status: undefined,
        page: 0
    }
    btnQ: MenuRole,
    statusListArr: any[] | undefined,
    selectData: {}
}
const defData: PageType = {
    ...BasePageDate,
    searchData: {
        title: undefined,
        status: undefined,
        page: 0
    },
    btnQ: {},
    statusListArr: [],
    selectData: {}
}

class GroupInfoListClass extends BaseListPage {

    state: PageType = {
        ...defData,
        btnQ: {}
    };

    private troupGroupAddModelResult: any = null;

    componentDidMount() {
        // BoneSelectStatus([105, 106, 107], this)
        super.componentDidMount();
    }

    loadData = () => {
        const {pageSize, searchData} = this.state;
        let url = `${API.group_info.query_page}?page=${searchData.page}&size=${pageSize}`;
        url = url + addParams("title", searchData.title, STRING_STR);
        url = url + addParams("status", searchData.status, NUMBER_STR);
        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                importLoading: false
            });
        })
    };


    changeCondition = (condition: any) => {
        const {searchData} = this.state;
        this.setState({
            searchData: {...searchData, ...condition},
        });
    };

    reset = () => {
        super.reset(defData.searchData);
    };

    handleKeyDown = (e: any) => {
        super.handleKeyDown(e);
    }

    closeModal = () => {

        this.loadData();
    }

    addInfo = () => {
        window.location.href = '/#/group_info/edit'
    }

    updateUp = (id: number, record: any) => {

        const that = this;
        confirm({
            title: `是否确认上架`,
            onOk() {
                that.doLoading()
                Fetch.putJSON(API.group_info.update_up, {id: id}).then(() => {
                    message.success('提交成功');
                    that.setState({
                        importLoading: false
                    })
                    that.loadData();
                });
            }
        })
    }

    updateDown = (id: number, record: any) => {

        const that = this;
        confirm({
            title: `是否确认下架`,
            onOk() {
                that.doLoading()
                Fetch.putJSON(API.group_info.update_down, {id: id}).then(() => {
                    message.success('提交成功');
                    that.setState({
                        importLoading: false
                    })
                    that.loadData();
                });
            }
        })
    }

    doLoading() {
        this.setState({
            importLoading: true
        })
    }

    render() {
        const {
            pageSize, totalElements,
            btnQ, searchData, content, selectId,
            statusListArr
        } = this.state;
        const columns: ColumnsType<User> = [

            {
                title: '封面图',
                dataIndex: 'cover',
                align: `center`,
                render: (text: any, record: any) => {
                    return (
                        <>
                            {text && (
                                <Avatar
                                    size={{xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100}}
                                    icon={<AntDesignOutlined/>}
                                    src={text.indexOf("http") >= 0 ? text : Api.domain + text}
                                />
                            )}
                        </>
                    );
                },
            },
            {
                title: '标题',
                dataIndex: 'title',
                align: `center`,
            },
            {
                title: '副标题',
                dataIndex: 'subTitle',
                align: `center`,
            },
            {
                title: '状态',
                dataIndex: 'status',
                align: `center`,
                render: (text: any, record: any) => {
                    return (
                        <>
                           <span style={{
                               padding: '5px 10px',
                               borderRadius: '15px',
                               border: '1px solid ',
                               color: DisplayStatusObj[text].color
                           }}>
                                {DisplayStatusObj[text].text}
                            </span>
                        </>
                    );
                },
            },
            {
                title: '操作',
                align: `center`,
                dataIndex: 'status',
                // width: 200,
                render: (value: any, record: any) => {
                    return (
                        <Space split={<Divider type="vertical"/>}>
                            {(record.status === 2) && (
                                <>
                                    {(btnQ.R277) && (
                                        <Button type={"link"} onClick={() => this.updateUp(record.id, record)}>
                                            发布
                                        </Button>
                                    )}
                                    {(btnQ.R276) && (
                                        <Link to={`/group_info/edit?id=${encryptData(record.id + "")}`}>
                                            编辑
                                        </Link>
                                    )}
                                </>
                            )}
                            {(record.status === 1 && (btnQ.R277)) && (
                                <Button type={"link"} danger onClick={() => this.updateDown(record.id, record)}>
                                    下架
                                </Button>
                            )}

                            <Link to={`/group_info/detail?id=${encryptData(record.id + "")}`}>
                                详情
                            </Link>
                        </Space>
                    );
                },
            },
        ];
        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >

                        <Flex justify={"space-between"}>
                            <Space onKeyDown={this.handleKeyDown} wrap>
                                <Input
                                    placeholder="标题"
                                    value={searchData.title}
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, title: e.target.value})
                                    }
                                    }
                                />
                                <Select
                                    style={{width: '100px'}}
                                    placeholder="发布状态"
                                    value={searchData.status}
                                    onChange={(e) => this.changeCondition({page: 0, status: e})}
                                >
                                    <Select.Option value="1">已发布</Select.Option>
                                    <Select.Option value="2">未发布</Select.Option>
                                </Select>
                                <Button onClick={this.loadData}>
                                    搜索
                                </Button>
                                <Button onClick={() => this.reset()}>重置</Button>
                            </Space>
                            {(btnQ.R276) && (
                                <Button type={"primary"} onClick={() => this.addInfo()}>新增</Button>
                            )}
                        </Flex>
                        <Table
                            // loading={this.state.loading}
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1
                                    }
                                )
                            }
                        />
                    </div>
                </Spin>
                {/*<TroupGroupAddModel*/}
                {/*    width={500}*/}
                {/*    title={'添加组'}*/}
                {/*    id={this.state.selectId}*/}
                {/*    data={this.state.selectData}*/}
                {/*    onCancel={this.closeModal}*/}
                {/*    ref={(c) => (this.troupGroupAddModelResult = c)}*/}
                {/*/>*/}
            </>
        );
    }

}

export default function GroupInfoList() {

    return (

        <App selectedKeys={['GroupInfoList']} openKeys="information">
            <GroupInfoListClass/>
        </App>
    );
}
