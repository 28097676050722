import React, {Component} from 'react';
import {Breadcrumb, Button, Input, Select, Space, Table} from 'antd';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import {ColumnsType} from 'antd/es/table';
import {Link} from 'react-router-dom';
import {User} from './types';
import 'dayjs/locale/zh-cn';
import App from '../../App';
import {parseGender, parseRole} from './UserDetail';

type TheState = {
    page: number;
    pageSize: number;
    totalElements: number;
    totalPages: number;
    content: User[];
    nickname: string;
    roleId: number | '';
    loading: boolean;
};

class UserList extends Component<{}, TheState> {
    state: TheState = {
        page: 0,
        pageSize: 10,
        totalElements: 0,
        totalPages: 0,
        content: [],
        nickname: '',
        roleId: '',
        loading: true,
    };

    componentDidMount() {
        this.loadData();
    }

    changeCondition = (condition: any, load = false) => {
        this.setState(
            {
                ...condition,
            },
            () => {
                if (load) {
                    this.loadData();
                }
            }
        );
    };

    loadData = () => {
        const {page, pageSize, nickname, roleId} = this.state;
        Fetch.get(
            `${API.user.list}?page=${page}&size=${pageSize}&nickname=${nickname}&roleId=${roleId}`
        ).then((data) => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                loading: false,
            });
        });
    };

    reset = () => {
        this.setState(
            {
                nickname: '',
                roleId: '',
                page: 0,
            },
            this.loadData
        );
    };

    handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            this.loadData();
        }
    };

    render() {
        const {page, pageSize, totalElements, content} = this.state;
        const columns: ColumnsType<User> = [
            {
                title: '微信昵称',
                dataIndex: 'wxNickname',
                align: `center`,
            },
            {
                title: '性别',
                dataIndex: 'gender',
                align: `center`,
                render: (text: any) => {
                    return parseGender(Number(text));
                },
            },
            {
                title: '用户角色',
                dataIndex: 'wxPhone',
                align: `center`,
                render: (text: any, record: any) => {
                    return `${parseRole(record.roleId)}`;
                },
            },
            {
                title: '最近登录时间',
                dataIndex: 'loginDate',
                align: `center`,
            },
            {
                title: '操作',
                dataIndex: 'createdDate',
                align: `center`,
                render: (text: any, record: any) => {
                    return (
                        <Space>
                            <Link to={`/user/detail/${record.id}`}>查看</Link>
                        </Space>
                    );
                },
            },
        ];

        return (
            <>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>用户管理</Breadcrumb.Item>
                </Breadcrumb>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Space onKeyDown={this.handleKeyDown} wrap>
                        昵称:
                        <Input
                            placeholder="请输入微信昵称"
                            value={this.state.nickname}
                            onChange={(e) =>
                                this.changeCondition({page: 0, nickname: e.target.value})
                            }
                        />
                        用户角色：
                        <Select
                            value={this.state.roleId}
                            onChange={(e) => this.changeCondition({roleId: e, page: 0})}
                            style={{width: '100px'}}
                        >
                            <Select.Option value="5">爱心网友</Select.Option>
                            <Select.Option value="1">志愿者</Select.Option>
                            <Select.Option value="3">乐龄志愿服务组织</Select.Option>
                        </Select>
                        <Button onClick={this.loadData} type="primary">
                            搜索
                        </Button>
                        <Button onClick={this.reset}>重置</Button>
                    </Space>
                    <Table
                        loading={this.state.loading}
                        className="mgTop15"
                        bordered
                        rowKey="id"
                        columns={columns}
                        dataSource={content}
                        pagination={{
                            current: page + 1,
                            pageSize,
                            total: totalElements,
                            showTotal: (total) => `共${total}条`,
                            showSizeChanger: false,
                        }}
                        onChange={(pagination: any) =>
                            this.changeCondition(
                                {
                                    page: pagination.current - 1,
                                    pageSize: pagination.pageSize,
                                },
                                true
                            )
                        }
                    />
                </div>
            </>
        );
    }
}

export default function UserIndex() {
    return (
        <App selectedKeys={['user']}>
            <UserList/>
        </App>
    );
}
