import API from "../../common/api";

export type Project = {
    id: number,
    userId: number,
    createdDate: Date,
    publishDate: Date,
    active: number | "",
    mediaId: number | "",
    status: string,
    reviewStatus: string,
    content: string,
    institution: string,
    validateDate: string,
    submitDate: string,
    publisher: string,
    offlineRole: number,
    covers: [],
}


export function readFile(url: string) {

    return new Promise((resolve, reject) => {
        if (url) {
            const urlTemp = API.domain + url;
            fetch(urlTemp)
                .then(response => response.text())
                .then(data => {
                    // 在这里处理获取的数据
                    resolve(data);
                })
                .catch(error => {
                    // 处理错误
                    console.log(error)
                });
        }
    })
}