import PATH from "../pagePath";

type configType = {
    url: string | ''
    selectedKeys: string | ''
    openKeys: string | ''
}

const AIER_OPHTHALMOLOGY = 'aierOphthalmology';
const DENTAL_HOSPITALS = "ORAL_GUARD";

const PAGE_KEY_MAP: Map<string, configType> = new Map();

PAGE_KEY_MAP.set(PATH.troup.guru.list, {
    url: PATH.troup.guru.list,
    selectedKeys: "TroupList",
    openKeys: 'TROUP'
});
PAGE_KEY_MAP.set(PATH.troup.group.list, {
    url: PATH.troup.group.list,
    selectedKeys: "TroupGroupList",
    openKeys: 'TROUP'
});
PAGE_KEY_MAP.set(PATH.troup.reserve.list, {
    url: PATH.troup.reserve.list,
    selectedKeys: "TroupReserveList",
    openKeys: 'TROUP'
});
PAGE_KEY_MAP.set(PATH.troup.sign.list, {
    url: PATH.troup.sign.list,
    selectedKeys: "TroupSignList",
    openKeys: 'TROUP'
});

PAGE_KEY_MAP.set(PATH.troup.group.detail, {
    url: PATH.troup.group.detail,
    selectedKeys: "TroupGroupList",
    openKeys: 'TROUP'
});

PAGE_KEY_MAP.set(PATH.activityInfo.list, {
    url: PATH.activityInfo.list,
    selectedKeys: "ActivityInfoList",
    openKeys: 'information'
});

PAGE_KEY_MAP.set(PATH.groupInfo.list, {
    url: PATH.activityInfo.list,
    selectedKeys: "GroupInfoList",
    openKeys: 'information'
});

PAGE_KEY_MAP.set(PATH.orgInfo.list, {
    url: PATH.orgInfo.list,
    selectedKeys: "OrgInfoList",
    openKeys: 'information'
});

PAGE_KEY_MAP.set(PATH.artInfo.list, {
    url: PATH.artInfo.list,
    selectedKeys: "ArtInfoList",
    openKeys: 'information'
});

export default PAGE_KEY_MAP;
