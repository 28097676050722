module.exports = {
    //local
    // pathDomain: 'http://127.0.0.1:8101',
    // cdnDomain: 'https://testcdn.lnyst.com/',
    // userCdnDomain: 'https://testcdn.lelingzhiyuan.com/',
    //sit
    // pathDomain: 'https://test.lnyst.com',
    // cdnDomain: 'https://testcdn.lnyst.com/',
    // userCdnDomain: 'https://testcdn.lelingzhiyuan.com/',
    //prod
    pathDomain: 'https://app.lnyst.com',
    cdnDomain: 'https://appcdn.lnyst.com/',
    userCdnDomain: 'https://appcdn.lelingzhiyuan.com/',

    staticImg: 'https://appcdn.lnyst.com/static'
}
//npm install --force